import React from "react"
import FadeIn from "../fadeIn/FadeIn"

const BlockTitle = ({ data }) => (
    <FadeIn>
        <div className="section">
            <h3>{data.titel}</h3>
        </div>
    </FadeIn>
)

export default BlockTitle
