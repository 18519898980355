import React from "react"
import { StaticQuery, graphql } from "gatsby"
import UniversalLink from "../UniversalLink"
import { CreateLocalLink } from "../../../utils"
import Arrow from "../../../images/arrow.svg"

import "./Nextprev.scss"

const IMAGE_QUERY = graphql`
    query GETIMAGE {
        wp {
            generalSettings {
                url
            }
        }
    }
`

const NextPrev = (pages) => {
    return (
        <StaticQuery
            query={IMAGE_QUERY}
            render={(data) => {
                const wordPressUrl = data.wp.generalSettings.url.replace(
                    "/wp",
                    "",
                )

                return (
                    <div className="section next-prev">
                        <div className="columns is-mobile columns is-variable is-0">
                            <div className="column is-half prev">
                                {pages.previous ? (
                                    <UniversalLink
                                        className="work-link"
                                        to={CreateLocalLink(
                                            pages.previous.link,
                                            wordPressUrl,
                                            "/work",
                                        )}
                                    >
                                        <h4>PREVIOUS PROJECT</h4>
                                        <div className="img-wrapper">
                                            <div className="overlay"
                                                 style={{
                                                     background: `url(${
                                                         pages.previous.work.afbeelding.mediaDetails.sizes.filter(
                                                             (size) =>
                                                                 size.name ===
                                                                 "thumbnail",
                                                         )[0].sourceUrl
                                                     }) center`,
                                                     backgroundSize: "cover",
                                                 }}
                                            />
                                            <Arrow className="arrow flipped" />
                                        </div>
                                    </UniversalLink>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="column is-half next">
                                {pages.next ? (
                                    <UniversalLink
                                        className="work-link"
                                        to={CreateLocalLink(
                                            pages.next.link,
                                            wordPressUrl,
                                            "/work",
                                        )}
                                    >
                                        <h4>NEXT PROJECT</h4>
                                        <div className="img-wrapper">
                                            <div className="overlay"
                                                 style={{
                                                     background: `url(${
                                                         pages.next.work.afbeelding.mediaDetails.sizes.filter(
                                                             (size) =>
                                                                 size.name ===
                                                                 "thumbnail",
                                                         )[0].sourceUrl
                                                     }) center`,
                                                     backgroundSize: "cover",
                                                 }}
                                            />
                                            <Arrow className="arrow" />
                                        </div>
                                    </UniversalLink>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}

export default NextPrev
