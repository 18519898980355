import React, { useContext, useEffect } from "react"
import BlockQuote from "../components/elements/blocks/BlockQuote"
import BlockText from "../components/elements/blocks/BlockText"
import BlockTitle from "../components/elements/blocks/BlockTitle"
import BlockImage from "../components/elements/blocks/BlockImage"
import BlockVideo from "../components/elements/blocks/BlockVideo"
import BlockDoubleImage from "../components/elements/blocks/BlockDoubleImage"
import BlockEmbed from "../components/elements/blocks/BlockEmbed"
import WorkMeta from "../components/elements/workMeta"
import NextPrev from "../components/elements/NextPrev/NextPrev"
import SEO from "../components/elements/SEO"

import "./work.scss"
import FadeIn from "../components/elements/fadeIn/FadeIn"
import { PageColorContext } from "../layouts/layout-context"
import { graphql, useStaticQuery } from "gatsby"


const Post = ({ pageContext }) => {
    const post = pageContext.post
    const data = useStaticQuery(graphql`
        {
            wp {
                themeOptions {
                    themeColors {
                        colors {
                            color
                            naam
                        }
                    }
                }
                generalSettings {
                    url
                }
            }
        }
    `)
    const {
        wp: {
            themeOptions: {
                themeColors: { colors },
            },
        },
    } = data

    useEffect(() => {
        let body = document.body
        body.classList.add("single-work")

        return function cleanup() {
            body.classList.remove("single-work")
        }
    })

    function renderBlocks(type, data, i) {
        switch (type) {
            case "Post_Blocks_Blocks_Tekst":
                return <BlockText key={i} data={data} />
            case "Post_Blocks_Blocks_SubTitel":
                return <BlockTitle key={i} data={data} />
            case "Post_Blocks_Blocks_Quote":
                return <BlockQuote key={i} data={data} />
            case "Post_Blocks_Blocks_Afbeelding":
                return <BlockImage key={i} data={data} />
            case "Post_Blocks_Blocks_DubbeleAfbeelding":
                return <BlockDoubleImage key={i} data={data} />
            case "Post_Blocks_Blocks_Video":
                return <BlockVideo key={i} data={data} />
            case "Post_Blocks_Blocks_Embed":
                return <BlockEmbed key={i} data={data} />
            default:
                return <pre>{JSON.stringify(type, null, 2)}</pre>
        }
    }

    const [pageColorContext, setPageColorContext] = useContext(PageColorContext)
    const forceerkleur = colors.find(color => color.color === post.work.forceerKleur)
    const metaInformatie = post.work.metaInformatie

    useEffect(() => {
        if (forceerkleur) setPageColorContext(colors.indexOf(forceerkleur))
    }, [forceerkleur])

    const wordPressUrl = data.wp.generalSettings.url.replace(process.env.WORDPRESS_URL, process.env.FRONTEND_URL)

    return (
        <main className="container is-fluid is-post">
            <SEO title={post.title} canonical={wordPressUrl + post.uri} image={post.work.afbeelding.sourceUrl}  />

            <FadeIn>
                <div className="section">
                    <h2>{post.title}</h2>
                </div>
            </FadeIn>

            {
                post.blocks.blocks ? (
                    post.blocks.blocks.map((block, index) =>
                        renderBlocks(block.fieldGroupName, block, index),
                    )
                ) : null
            }

            <FadeIn>
                {metaInformatie ? (
                    <WorkMeta meta={metaInformatie} />
                ) : null}
            </FadeIn>

            <FadeIn>
                <NextPrev next={post.next} previous={post.previous} />
            </FadeIn>
        </main>
    )
}

export default Post
