import React from "react"

const WorkMeta = (data) => (
    <div className="section">
        <div className="columns is-multiline">
            {data.meta.map((meta, index) => (
                <div key={index} className="column is-half">
                    <p>
                        <b>{meta.titel}</b><br/>
                        {meta.informatie}
                    </p>
                </div>
            ))}
        </div>
    </div>
)

export default WorkMeta