import React, { useEffect, useState } from "react"
import FadeIn from "../fadeIn/FadeIn"
import { findProvider } from "oembed-parser"
import Embed from "react-tiny-oembed"

const BlockEmbed = ({ data }) => {
    const provider = findProvider(data.embed).providerName
    const parsedUrl = new URL(data.embed)
    const supportedVideoTypes = ["YouTube", "Youtube", "Vimeo"]

    return (
        <FadeIn>
            <div className="section" title={`embedded ${provider}`}>
                {provider === "Spotify" ? (
                    <iframe
                        title="Spotify Web Player"
                        src={`https://open.spotify.com/embed${parsedUrl.pathname}`}
                        width={"100%"}
                        height={"250"}
                        frameBorder={"0"}
                        allow="encrypted-media"
                        style={{
                            maxWidth: "780px",
                            margin: "auto",
                            overflow: "hidden",
                            borderRadius: 0,
                            display: "block",
                        }}
                    />
                ) : null}
                {!!supportedVideoTypes.includes(provider) && (
                    <Embed url={data.embed} options={{ maxwidth: "780px" }} />
                )}
            </div>
        </FadeIn>
    )
}

export default BlockEmbed