import React from "react"
import Image from "../image/Image"

const BlockImage = ({ data }) => (
    <div className="section">
        <Image
            src={data.afbeelding.sourceUrl}
            srcSet={data.afbeelding.srcSet}
        />
    </div>
)

export default BlockImage
