import React from "react"
import { Parallax } from 'react-scroll-parallax';
import Image from "../image/Image";
import './BlockDoubleImage.scss'

const BlockDoubleImage = ({ data }) => (
    <div className="section">
        <div className={"columns double_image " + data.positie}>
            <div className={"column " +
             (data.positie === "schuin" ? 'is-7-dekstop': '')}>
                <Parallax y={[5, 15]}>
                    <Image
                        src={data.afbeelding1.sourceUrl}
                        srcSet={data.afbeelding1.srcSet}
                    />
                </Parallax>
            </div>
            <div className={"column second_column " 
            + (data.positie === "schuin" ? 'is-7-dekstop': '')
            + " "
            + (data.positie === "kleiner_groter" ? 'is-kleiner': '')}>
                <Parallax y={[-15, 25]}>
                    <Image
                        src={data.afbeelding2.sourceUrl}
                        srcSet={data.afbeelding2.srcSet}
                        delay={500}
                    />
                </Parallax>
            </div>
        </div>
    </div>
)

export default BlockDoubleImage
