import React from "react"
import FadeIn from "../fadeIn/FadeIn"

const BlockText = ({ data }) => (
    <FadeIn>
        <div
            className="section content"
            dangerouslySetInnerHTML={{ __html: data.tekst }}
        ></div>
    </FadeIn>
)

export default BlockText
