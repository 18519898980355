import React from "react"
import FadeIn from "../fadeIn/FadeIn"

const BlockVideo = ({ data }) => (
    <FadeIn>
        <div className="section">
            <video
                className="video-responsive"
                playsInline
                autoPlay
                muted
                loop
                preload="auto"
            >
                <source src={data.video.guid} type="video/mp4" />
            </video>
        </div>
    </FadeIn>
)

export default BlockVideo
