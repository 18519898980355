import React from "react"
import FadeIn from "../fadeIn/FadeIn"

const BlockQuote = ({ data }) => (
    <FadeIn>
        <div className="section">
            <h3>{data.quoteTekst}</h3>
            {!!data.onderSchrift ? (
                data.onderSchrift
            ) : (
                <p>{data.onderSchrift}</p>
            )}
        </div>
    </FadeIn>
)

export default BlockQuote
